import React, { useEffect, useState } from "react"

import Layout from "../../../structure/Layout/Layout"
import Seo from "../../../structure/Seo/Seo"
import Editor from "../../../element/Editor/Editor"
import "./styles.scss"
import { clearLSFilters } from "../../../../utils/Data"
import CitySkyline from "../../../element/Svg/CitySkyline"

const WithUsPage = () => {
  const [path, setPath] = useState("")

  useEffect(() => {
    clearLSFilters()
  }, [])

  return (
    <Layout current="avec-nous">
      <Seo title="Avec nous | Vu des Quartiers" />
      <div
        className="page page-grid page-cold page-withus"
        data-theme="primary-25"
      >
        <div className="page_inner">
          <section className="section section-main section-page section-header mini section-description">
            <div
              className="section_inner radius radius-small radius-bottom radius-left"
              data-theme="primary"
            >
              <div className="section-title">
                <h1 className="small">
                  <b>Avec nous</b>
                </h1>
              </div>
            </div>
            <CitySkyline />
          </section>
          <section className="section section-secondary section-page">
            <div
              className="section_inner radius radius-small radius-bottom radius-right"
              data-theme="white"
            >
              <div className="list-secion">
                <div className="withus_list">
                  <h2 className="wrapped"><span>Les membres de l’Association</span></h2>
                  <Editor
                    content={`
                      <div class="editor-paragraph">
                        <p>
                          <img src="/assets/images/partners/ville-banlieue.svg" class="ville-banlieue" alt=" ville-banlieue">
                        </p>
                        <p>
                          <srong>L’Association des Maires Ville & Banlieue de France existe depuis 1983. Elle est contemporaine de la politique de la ville. Pour ses maires fondateurs, il fallait à la fois favoriser le développement des quartiers les plus fragiles du territoire et valoriser l’image des villes de banlieue.</strong>
                        </p>
                      </div>
                    `}
                  />
                  <div className="list-logos">
                    <div className="partners partners-asso">
                      <div className="partners_list partners_first">
                        <div className="partners__items">
                          <div className="first">
                            <div className="image">
                              <img
                                src="/assets/images/partners/bfm-tv.svg"
                                className="bfm-tv"
                                alt=" BFM TV"
                              />
                            </div>
                          </div>
                          <div className="second">
                            <div className="image">
                              <img
                                src="/assets/images/partners/bfm-regions.svg"
                                className="bfm-regions"
                                alt=" BFM Régions"
                              />
                            </div>
                          </div>
                          <div className="third">
                            <div className="image">
                              <img
                                src="/assets/images/partners/rmc-story.svg"
                                className="rmc-story"
                                alt=" RMC Story"
                              />
                            </div>
                          </div>
                          <div className="fourth">
                            <div className="image">
                              <img
                                src="/assets/images/partners/rmc-sport.svg"
                                className="rmc-sport"
                                alt=" RMC Sport"
                              />
                            </div>
                          </div>
                      </div>


                    </div>
                    <div className="partners_list partners_second">
                      <div className="partners__items">
                        <div className="first">
                          <div className="image">
                            <img
                              src="/assets/images/partners/radiofrance.svg"
                              className="radiofrance"
                              alt=" Radio France"
                            />
                          </div>
                        </div>
                        <div className="second">
                          <div className="image">
                            <img
                              src="/assets/images/partners/francetv.svg"
                              className="francetv"
                              alt=" France Télévision"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="list-secion">
              <h2 className="wrapped"><span>Ils nous accompagnent :</span></h2>
              <div className="list-logos">
                <div className="members">
                  <img
                    src="/assets/images/partners/assises-journalisme-web.png"
                    className="assises-journalisme"
                    alt=""
                  />
                </div>
              </div>
              <Editor
                content={`
                <div class="editor-paragraph">
                  <p>
                    Les Assises Internationales du Journalisme ont été créées pour tenter de définir les conditions de production d’une information de qualité dans la France du XXIe siècle.
                  </p>
                  <p>
                    Soutenue par l’ensemble des acteurs de la profession, respectueuse de la liberté de chacun, la manifestation se veut avant tout un lieu d’échange et de réflexion sur le journalisme et sa pratique, indépendant de toute tutelle. Un lieu ouvert aux journalistes et aux éditeurs, aux étudiants et enseignants, aux chercheurs, mais aussi et surtout aux citoyens.
                  </p>
                </div>
              `}
              />
            </div>


            <div className="list-secion">
              <h2 className="wrapped"><span>Avec le soutien de :</span></h2>
              <div className="list-logos">
                <div className="partners partners-gouv">
                  <div className="partners_list partners_first">
                    <div className="partners__items">
                      <div className="first">
                        <div className="image">
                          <img src="/assets/images/partners/rf.svg" className="rf" alt=""></img>
                        </div>
                      </div>
                      <div className="second">
                        <div className="image">
                          <img src="/assets/images/partners/anct.svg" className="acnt" alt=""></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="partners_list partners_second">
                    <div className="partners__items">
                      <div className="first">
                        <div className="image">
                          <img src="/assets/images/partners/idf.svg" className="idf" alt=""></img>
                        </div>
                      </div>
                      <div className="second">
                        <div className="image">
                          <img src="/assets/images/partners/normandie.svg" className="normandie" alt=""></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
      </div >
    </Layout >
  )
}

export default WithUsPage
